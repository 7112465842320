import { MultiPillSelectResult } from "src/components/Paradise/MultiPillSelect"
import { ParadiseSettingMultiPillSelect } from "src/components/Paradise/ParadiseSettings/ParadiseSettingMultiPillSelect"
import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePutOrganizationTags } from "src/data/organizations/queries/organizationQueries"
import { MBadge } from "src/ui/MBadge/MBadge"

export function ParadiseOrganizationTags({
  orgId,
  currentTags,
  disabled,
}: {
  orgId: string
  currentTags: string[] | undefined
  disabled?: boolean
}) {
  const putOrganizationTags = usePutOrganizationTags()

  async function handleSaveTags(
    selectedTags: MultiPillSelectResult[]
  ): TSettingContainerOnSaveReturnType {
    try {
      await putOrganizationTags.mutateAsync({
        orgId: orgId,
        body: {
          tags: selectedTags.map((t) => t.id),
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <ParadiseSettingMultiPillSelect
      title="Tags"
      initialSelected={convertToMultiPillResult(currentTags ?? [])}
      options={convertToMultiPillResult(currentTags ?? [])}
      onSave={handleSaveTags}
      displayValue={
        currentTags && currentTags.length > 0 ? (
          <PillsWrapper>
            {currentTags.map((tag) => (
              <MBadge key={tag} size="small" color="neutral">
                {tag}
              </MBadge>
            ))}
          </PillsWrapper>
        ) : (
          "-"
        )
      }
      disabled={disabled}
    />
  )
}

function convertToMultiPillResult(arr: string[]): MultiPillSelectResult[] {
  return arr.map((s) => ({ id: s, name: s }))
}
